<template>
	<Dialog :dialog="ExtendInvoiceDialog" :dialog-width="900" style="z-index: 1000 !important">
		<template v-slot:title
			>Extension Invoice
			<div class="ml-2" v-if="invoiceManage.extend_count > 1">
				<v-avatar color="red" size="36">
					<span class="white--text"> E {{ invoiceManage.extend_count - 1 }}</span>
				</v-avatar>
			</div>
		</template>
		<template v-slot:body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="mb-3">
					<!-- <v-col md="4" class="py-0">
						<label for="" class="required">Invoice #</label>
					</v-col>
					<v-col md="8" class="py-0">
						<v-text-field hide-details outlined placeholder="Invoice #" v-model="invoiceManage.barcode" :rules="[vrules.required(invoiceManage.barcode, 'Invoice #')]"
										:class="{
											required: !invoiceManage.barcode,
										}"
						
						>
						</v-text-field>
					</v-col> -->
					<v-col md="12" class="py-0">
						<v-radio-group v-model="extention.select_type" row hide-details class="mt-0">
							<v-radio class="custome-radio" value="single"></v-radio>
							<v-chip color="primary" label outlined x-small class="ml-2" style="margin-right: 90px">
								Extend this invoice
							</v-chip>
							<v-radio class="custome-radio" value="multiple"></v-radio>
							<v-chip color="red" label outlined x-small class="ml-2">
								Extend all upcoming invoice
							</v-chip>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="" class="required">Extend Period</label>
					</v-col>
					<v-col md="4" class="py-0">
						<v-text-field
							hide-details
							outlined
							class="currency-input"
							placeholder="duration"
							type="number"
							:min="0"
							v-mask="'###########'"
							:max="extention.time_period == 'month' ? 12 : 365"
							v-on:keyup="get_extend_date('duration'), prevention($event)"
							@paste="onPaste"
							v-model="extention.duration"
							:rules="[vrules.required(extention.duration, 'Extend Duration')]"
							:class="{
								required: !extention.duration,
							}"
						></v-text-field>
					</v-col>
					<v-col md="4" class="py-0">
						<v-autocomplete
							:items="timePeriodList"
							item-text="text"
							item-value="value"
							v-model="extention.time_period"
							v-on:change="get_extend_date('period')"
							hide-details
							outlined
							:rules="[vrules.required(extention.time_period, 'Extend Period')]"
							placeholder="Extend Period"
							:class="{
								required: !extention.time_period,
							}"
						>
						</v-autocomplete>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" cols="12" class="my-auto py-0">
						<label class="input-label">Old Date</label>
					</v-col>
					<v-col md="4" class="my-auto py-0"><label class="input-label">Add Date</label> </v-col>
					<v-col md="4" class="my-auto py-0">
						<label class="input-label">Due Date</label>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for=""></label>
					</v-col>
					<v-col md="4" class="py-0">
						<span class="primary--text fw-600">
							<v-chip label color="red white--text" outlined text-color="" x-small>
								<span class="fw-500"> {{ addDate(invoiceManage.start_date) }}</span>
							</v-chip>
						</span>
					</v-col>
					<v-col md="4" class="py-0">
						<span class="primary--text fw-600">
							<v-chip label color="green white--text" outlined text-color="" x-small>
								<span class="fw-500"> {{ formatDate(invoiceManage.start_date) }}</span>
							</v-chip>
						</span>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" cols="12" class="my-auto py-0">
						<label class="input-label">Extend Dates</label>
					</v-col>
					<v-col md="4" class="my-auto py-0"><label class="input-label">Add Date</label> </v-col>
					<v-col md="4" class="my-auto py-0">
						<label class="input-label">Due Date</label>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for=""></label>
					</v-col>
					<v-col md="4" class="py-0">
						<v-chip label color="red white--text" outlined text-color="" x-small>
							<span class="fw-500"> {{ addDate(extention.extend_due_date) }}</span>
						</v-chip>
						<!-- <DatePicker hide-details outlined custom-class="mt-0" v-model="extention.extend_add_date" readonly>
						</DatePicker> -->
					</v-col>
					<v-col md="4" class="py-0">
						<v-chip label color="green white--text" outlined text-color="" x-small>
							<span class="fw-500"> {{ formatDate(extention.extend_due_date) }}</span>
						</v-chip>
						<!-- <DatePicker hide-details outlined custom-class="mt-0" v-model="extention.extend_due_date" readonly>
						</DatePicker> -->
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="" class="required">Reason</label>
					</v-col>
					<v-col md="8" class="my-auto py-0 pr-10 position-relative">
						<v-autocomplete
							:items="reasonList"
							item-text="text"
							item-value="value"
							hide-details
							outlined
							placeholder="Reason"
							v-model="extention.reason"
							:rules="[vrules.required(extention.reason, 'Extend Reason')]"
							:class="{
								required: !extention.reason,
							}"
						>
						</v-autocomplete>
						<ExtendReasons
							:title="'Extend Reason'"
							:type-id="60"
							v-on:success="getSettings"
						></ExtendReasons>
						<!-- <v-autocomplete :items="reasonList" item-text="text" item-value="value" v-model="extention.reason"
							hide-details outlined placeholder="Extend Period">
						</v-autocomplete> -->
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Remark</label>
					</v-col>
					<v-col md="8" class="py-0">
						<TextAreaInput
							:rows="4"
							dense
							ref="comment"
							hide-details
							v-model="extention.remark"
							placeholder="Enter Remark..."
							:disabled="pageLoading"
							:word-limit="200"
							:loading="pageLoading"
						></TextAreaInput>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="4" class="py-0">
						<label for="">Summary</label>
					</v-col>
					<v-col md="8" class="py-0">
						<span class="text-danger fw-600" v-if="extention.select_type == 'single'">
							Only current invoice reflected</span
						>
						<!-- <span class="text-danger fw-600" v-if="extention.select_type == 'multiple'">
							Project # {{ projectData.barcode }}, {{ projectData.invoice_count }} invoice reflected</span
						> -->
						<span class="text-danger fw-600" v-if="extention.select_type == 'multiple'">
							Project # {{ projectData.barcode }}, Only upcoming invoices reflected</span
						>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
/* import TextInput from "@/view/components/TextInput"; */
/* import DatePicker from "@/view/components/DatePicker"; */
import { POST, GET } from "@/core/services/store/request.module";
import MomentJS from "moment-timezone";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import { toSafeInteger } from "lodash";
import TextAreaInput from "@/view/components/TextAreaInput";
import ExtendReasons from "@/view/module/components/ExtendReasons";

export default {
	mixins: [MainMixin],
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		ExtendInvoiceDialog: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		projectData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		customerDataId: {
			type: Number,
			default: 0,
		},
		projectId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			reasonList: [],
			extention: {
				select_type: "single",
				time_period: "month",
				duration: 1,
				extend_add_date: null,
				extend_due_date: null,
				reason: "blue",
				remark: null,
				end_date: null,
			},
			timePeriodList: [
				{ value: "month", text: "Monthly" },
				{ value: "day", text: "Days" },
			],
			invoiceManage: {
				id: 0,
				barcode: null,
				actual_date: null,
				payable_amount: 0,
				paid_amount: 0,
				start_date: null,
			},
			files: [
				{
					file: null,
					name: null,
				},
			],
		};
	},
	watch: {
		updatedData: {
			deep: true,
			immediate: true,
			handler(param) {
				this.init(param);
				this.get_extend_date();
			},
		},
	},
	components: {
		Dialog,
		/* 	DatePicker, */
		/* TextInput, */
		TextAreaInput,
		ExtendReasons,
	},

	methods: {
		prevention(event) {
			let vv = event.target.value;
			if (this.extention.time_period == "month" && +vv > 12) {
				this.extention.duration = null;
			}
			if (this.extention.time_period == "day" && +vv > 365) {
				this.extention.duration = null;
			}
		},
		onPaste(evt) {
			let num = evt.clipboardData.getData("Text");
			if (this.extention.time_period == "month" && +num > 12) {
				evt.preventDefault();
			}
			if (this.extention.time_period == "day" && +num > 365) {
				evt.preventDefault();
			}
		},
		addDate(value) {
			let newDate = MomentJS(value, "YYYY-MM-DD").subtract(15, "days").format("YYYY-MM-DD");
			return this.formatDate(newDate);
		},
		init(data) {
			this.invoiceManage = {
				id: data.id,
				customer: this.customerDataId,
				actual_date: data.actual_date
					? MomentJS(data.actual_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
					: null,
				barcode: data.barcode ? data.barcode : null,
				payable_amount: data.payable_amount ? data.payable_amount : data.seo ? data.seo.cost : 0,
				paid_amount: data.paid_amount ? data.paid_amount : 0,
				start_date: data.start_date ? data.start_date : null,
			};
			(this.extention.remark = data.extend_remark ? data.extend_remark : null),
				(this.extention.reason = data.extend_reason ? data.extend_reason : null),
				(this.extention.select_type = data.extend_select_type ? data.extend_select_type : "single"),
				(this.extention.time_period = data.extend_time_period ? data.extend_time_period : "month"),
				(this.extention.extend_duration = data.extend_duration ? data.extend_duration : 0);
			this.extention.end_date = data.end_date ? data.end_date : 0;
		},
		get_extend_date(type = "") {
			if (this.extention.duration > 12 && type == "period") {
				this.extention.duration = 12;
			}
			let newDate = this.addDate();
			if (this.extention.time_period == "month") {
				this.extention.extend_add_date = MomentJS(newDate)
					.add(this.extention.duration, "months")
					.format("YYYY-MM-DD");
				this.extention.extend_due_date = MomentJS(this.invoiceManage.start_date)
					.add(this.extention.duration, "months")
					.format("YYYY-MM-DD");
			}
			if (this.extention.time_period == "day") {
				this.extention.extend_add_date = MomentJS(newDate)
					.add(this.extention.duration, "days")
					.format("YYYY-MM-DD");
				this.extention.extend_due_date = MomentJS(this.invoiceManage.start_date)
					.add(this.extention.duration, "days")
					.format("YYYY-MM-DD");
			}
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "invoice-setting",
				})
				.then((data) => {
					_this.reasonList = data.reason;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let formData = new FormData();
			if (this.projectId && this.projectId) {
				formData.append("project", this.projectId ? toSafeInteger(this.projectId) : 0);
			}

			if (this.extention && this.extention.select_type) {
				formData.append(
					"select_type",
					this.extention.select_type ? this.extention.select_type : "single"
				);
			}
			if (this.extention && this.extention.time_period) {
				formData.append(
					"time_period",
					this.extention.time_period ? this.extention.time_period : "month"
				);
			}

			if (this.extention && this.extention.duration) {
				formData.append(
					"duration",
					this.extention.duration ? toSafeInteger(this.extention.duration) : 0
				);
			}
			if (this.extention && this.extention.extend_add_date) {
				formData.append(
					"extend_add_date",
					this.extention.extend_add_date ? this.extention.extend_add_date : null
				);
			}
			if (this.extention && this.extention.extend_due_date) {
				formData.append(
					"extend_due_date",
					this.extention.extend_due_date ? this.extention.extend_due_date : null
				);
			}
			if (this.extention && this.extention.reason) {
				formData.append("reason", this.extention.reason ? this.extention.reason : null);
			}
			if (this.extention && this.extention.remark) {
				formData.append("remark", this.extention.remark ? this.extention.remark : null);
			}
			if (this.extention && this.extention.end_date) {
				formData.append("end_date", this.extention.end_date ? this.extention.end_date : null);
			}

			let requestTYPE = POST;
			let requestURL = `invoice-extend/${this.updatedData.id}`;

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Invoice updated successfully." },
						this.$emit("success", true),
					]);

					this.$refs.extendCheckoutForm.reset();

					//this.UpdateInvoiceDialog = false;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},

	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		this.getSettings();
	},
};
</script>
